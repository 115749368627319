/** @jsx jsx */
import { useRef, useState } from 'react'
import Papa from 'papaparse'
import { Box, Button, Flex, jsx, Text } from 'theme-ui'
import Checkmark from '~/assets/images/icons/checkmark.inline.svg'
import CloseIcon from '~/assets/images/icons/close.inline.svg'
import VisuallyHidden from '~/components/Generic/VisuallyHidden'
import { useGroupOrder } from '~/hooks/components/use-group-order'

const DATA_NAME_COORDS = [0, 5]

const DATA_COMPANY_COORDS = [1, 5]

const DATA_HEADERS = {
  'Email address': 'email',
  'First name': 'firstName',
  'Last name': 'lastName',
  'Address line 1': 'address1',
  'Address line 2': 'address2',
  City: 'city',
  State: 'state',
  'ZIP code': 'zipcode',
}

const UPLOAD_ERROR_FILETYPE = 'The uploaded file was not a CSV.'

const UPLOAD_ERROR_GENERIC = 'The uploaded file was not properly formatted.'

const UPLOAD_ERROR_SUFFIX =
  'Please consult the instructions above and if you continue to have trouble, reach out to our events concierge at hq@makerwine.com.'

const GroupOrderAddressesStepUploadForm = () => {
  const { error, setError, updateAddresses, updateDetails } = useGroupOrder()
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const [uploadedFilename, setUploadedFilename] = useState(null)
  const dropLabel = useRef(null)
  let headerRow = null

  const handleClick = (event) => {
    dropLabel.current.click()
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    if (!isDraggingOver) setIsDraggingOver(true)
  }

  const handleDragLeave = (event) => {
    event.preventDefault()
    if (isDraggingOver) setIsDraggingOver(false)
  }

  const handleDrop = (event) => {
    event.preventDefault()
    setIsDraggingOver(false)

    if (event.dataTransfer.items) {
      const file = [...event.dataTransfer.items].find(
        (item) => item.kind === 'file'
      )
      if (file) parseFile(file.getAsFile())
    } else if (event.dataTransfer.files) {
      parseFile(event.dataTransfer.files?.[0])
    }
  }

  const handleUpload = (event) => {
    event.preventDefault()
    parseFile(event.target?.files?.[0])
  }

  const parseFile = (file) => {
    if (!file || file.type !== 'text/csv')
      return setError(UPLOAD_ERROR_FILETYPE, {
        type: 'filetype',
        filetype: file.type,
      })

    parseCsv(file, { skipEmptyLines: 'greedy' })
    setUploadedFilename(file.name)
  }

  const parseCsv = (input, config) =>
    Papa.parse(input, { complete: parseComplete, ...config })

  const parseComplete = (results) => {
    const data = results.data
    const errors = results.errors

    if (errors.length)
      return setError(UPLOAD_ERROR_GENERIC, {
        type: 'parse',
        errors,
      })

    // Remove the instruction rows and reparse.
    if (headerRow === null) {
      headerRow = data.findIndex(
        (row) => row[0] === Object.keys(DATA_HEADERS)[0]
      )

      // First, pull their name and company from the instruction region.
      const name = data?.[DATA_NAME_COORDS[0]]?.[DATA_NAME_COORDS[1]]
      const company = data?.[DATA_COMPANY_COORDS[0]]?.[DATA_COMPANY_COORDS[1]]

      if (name) {
        updateDetails({
          firstName: name.split(' ')?.[0],
          lastName: name.split(' ')?.[1],
        })
      }

      if (company) {
        updateDetails({ company })
      }

      return parseCsv(Papa.unparse(data.slice(headerRow), { header: false }), {
        header: true,
        transformHeader: transformCsvHeader,
      })
    }

    // Remove the result of extra empty columns from Excel.
    data.forEach((row, index) => {
      if (typeof row.undefined === 'string') delete row.undefined
    })

    // Spot check a header value.
    if (!Object.keys(data?.[0])?.includes(Object.values(DATA_HEADERS)[0]))
      return setError(UPLOAD_ERROR_GENERIC, {
        type: 'headers',
        headers: Object.keys(data?.[0]),
      })

    // Spot check the column count.
    if (Object.keys(data?.[0]).length !== Object.keys(DATA_HEADERS).length)
      return setError(UPLOAD_ERROR_GENERIC, {
        type: 'columns',
        headers: Object.keys(data?.[0]),
      })

    updateAddresses(data)
    setError(null)
  }

  const transformCsvHeader = (header) => DATA_HEADERS?.[header]

  return (
    <Box mt={3}>
      {(!uploadedFilename || error) && (
        <form>
          <Box
            py={4}
            sx={{
              bg: isDraggingOver ? 'backgroundDark' : 'backgroundMedium',
              border: '1px dashed',
              borderColor: isDraggingOver ? 'greyDark' : 'greyMedium',
              borderRadius: '10px',
              cursor: 'pointer',
              textAlign: 'center',
              '&:active': {
                bg: 'backgroundDark',
                borderColor: 'greyDark',
              },
            }}
            onClick={(event) => handleClick(event)}
            onDrop={(event) => handleDrop(event)}
            onDragLeave={(event) => handleDragLeave(event)}
            onDragOver={(event) => handleDragOver(event)}
          >
            <Box>
              <Text
                as="label"
                htmlFor="addressUpload"
                variant="description"
                color={isDraggingOver ? 'black' : 'greyDark'}
                ref={dropLabel}
                sx={{ cursor: 'pointer' }}
                onClick={(event) => event.stopPropagation()}
              >
                {'Drag and drop here, or '}
                <Text
                  as="span"
                  color="brandPrimary"
                  sx={{ textDecoration: 'underline' }}
                >
                  {'browse files'}
                </Text>
              </Text>
            </Box>
            <VisuallyHidden>
              <input
                type="file"
                id="addressUpload"
                accept=".csv"
                onChange={(event) => handleUpload(event)}
              />
            </VisuallyHidden>
          </Box>
        </form>
      )}
      {uploadedFilename && !error && (
        <Flex
          bg="white"
          p={2}
          sx={{
            alignItems: 'center',
            borderRadius: '10px',
            justifyContent: 'space-between',
          }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            <Text as="p" variant="body3">
              {uploadedFilename}
            </Text>
            <Flex
              bg="brandSecondary"
              ml={1}
              sx={{
                alignItems: 'center',
                borderRadius: '50%',
                flexShrink: '0',
                justifyContent: 'center',
                color: 'white',
                height: '24px',
                width: '24px',
              }}
            >
              <Checkmark height="12px" width="14px" />
            </Flex>
          </Flex>
          <Button
            variant="reset"
            sx={{ flexShrink: '0', height: '40px', width: '40px' }}
          >
            <CloseIcon
              onClick={() => setUploadedFilename(null)}
              sx={{ height: '16px', width: '16px' }}
            />
          </Button>
        </Flex>
      )}
      {error && (
        <Text as="p" variant="body3" color="error" mt={2}>
          {`${error} ${UPLOAD_ERROR_SUFFIX}`}
        </Text>
      )}
    </Box>
  )
}

export default GroupOrderAddressesStepUploadForm
