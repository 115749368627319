/** @jsx jsx */
import { jsx } from 'theme-ui'
import GroupOrderFinalizeStepConfirmation from '~/components/GroupOrder/Step/Finalize/Confirmation'
import GroupOrderFinalizeStepForm from '~/components/GroupOrder/Step/Finalize/Form'
import { useGroupOrder } from '~/hooks/components/use-group-order'

const GroupOrderFinalizeStep = () => {
  const { submitted } = useGroupOrder()

  if (submitted) {
    return <GroupOrderFinalizeStepConfirmation />
  }

  return <GroupOrderFinalizeStepForm />
}

export default GroupOrderFinalizeStep
