/** @jsx jsx */
import { Link } from 'gatsby'
import { Box, Container, Grid, Heading, jsx, Text } from 'theme-ui'

const GroupOrderFinalizeStepConfirmation = () => {
  return (
    <Box pt={[6, null, 8]} pb={[9, null, 10]}>
      <Container>
        <Grid columns={[null, 12]}>
          <Box
            sx={{
              gridColumn: [null, '3 / 11', '3 / 11', '4 / 10'],
            }}
          >
            <Heading as="h1" variant="title2" mb={[2, null, 3]}>
              {'Thank you!'}
            </Heading>
            <Text as="p" variant="body2" mb={2}>
              {
                'You will receive an email confirmation shortly and an invoice once our team reviews your order. '
              }
              <Text as="span" sx={{ fontWeight: 'bold' }}>
                {'Your order is not confirmed until the invoice is paid.'}
              </Text>
            </Text>
            <Link to="/" sx={{ textDecoration: 'none' }}>
              <Text as="span" variant="textLink">
                {'Keep shopping Maker Wine'}
              </Text>
            </Link>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default GroupOrderFinalizeStepConfirmation
