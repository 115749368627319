/** @jsx jsx */
import { Box, Button, Container, Grid, Heading, jsx, Text } from 'theme-ui'
import { useGroupOrder } from '~/hooks/components/use-group-order'

const GroupOrderAddonsStep = ({ addonSkus, stepBackward, stepForward }) => {
  const { products, updateAddons } = useGroupOrder()

  const handleVirtualAddonClick = async (optedIn) => {
    const lineItems = optedIn
      ? [{ quantity: 1, sku: addonSkus.virtualTasting }]
      : []

    await updateAddons(lineItems)
    stepForward()
  }

  let title = 'Would you like to add a Virtual Tasting?'
  let subtitle = '$150 per group'
  let confirm = 'Yes, please'
  let deny = 'No, thanks'
  let handleConfirm = () => handleVirtualAddonClick(true)
  let handleDeny = () => handleVirtualAddonClick(false)

  // Make dynamic if needed for additional products.
  if (products?.[0]?.sku === 'mwc-advent-calendar') {
    subtitle = 'Sorry, this is not available for the Advent Calendar.'
    confirm = 'Continue'
    deny = 'Go back'
    handleConfirm = () => handleVirtualAddonClick(false)
    handleDeny = () => stepBackward()
  }

  return (
    <Box pt={[6, null, 8]} pb={[9, null, 10]}>
      <Container>
        <Grid columns={[null, 12]}>
          <Box
            sx={{
              gridColumn: [null, '3 / 11', null, '4 / 10'],
              textAlign: 'center',
            }}
          >
            <Heading
              as="h1"
              variant="title2"
              mb={3}
              mx="auto"
              sx={{ maxWidth: [null, '400px', null, '530px'] }}
            >
              {title}
            </Heading>
            {subtitle && (
              <Text as="p" variant="title5">
                {subtitle}
              </Text>
            )}
            <Grid columns={[null, null, 2]} mt={5} sx={{ rowGap: 2 }}>
              <Box>
                <Button
                  variant="secondary"
                  onClick={() => handleDeny()}
                  sx={{ width: '100%' }}
                >
                  {deny}
                </Button>
              </Box>
              <Box>
                <Button onClick={() => handleConfirm()} sx={{ width: '100%' }}>
                  {confirm}
                </Button>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default GroupOrderAddonsStep
