/** @jsx jsx */
import { Box, Button, Container, Grid, Heading, jsx, Text } from 'theme-ui'
import GroupOrderAddressesStepUploadForm from '~/components/GroupOrder/Step/Addresses/UploadForm'
import { useGroupOrder } from '~/hooks/components/use-group-order'

const GroupOrderAddressesStep = ({ stepForward }) => {
  const { addresses, error, setError } = useGroupOrder()

  const handleContinue = () => {
    if (error || addresses.length === 0)
      return setError('Upload addresses before continuing.')

    stepForward()
  }

  return (
    <Box pt={[6, null, 8]} pb={[9, null, 10]}>
      <Container>
        <Grid columns={[null, 12]}>
          <Box
            sx={{
              gridColumn: [null, '3 / 11', '3 / 11', '4 / 10'],
            }}
          >
            <Box>
              <Heading as="h1" variant="title2" mb={[1, null, 2]}>
                {'Addresses'}
              </Heading>
              <Text as="p" variant="body2">
                {
                  'Please upload your recipients addresses. We can not ship to P.O. Boxes or to the following states: AK, AR, DE, HI, MS, RI, UT.'
                }
              </Text>
              <Box as="ol" mt={2} pl="20px" variant="text.body2">
                <Box as="li">
                  <Text as="p">
                    {'Make a copy of this '}
                    <a
                      href="https://docs.google.com/spreadsheets/d/1IwGV7GjDd2HZxiI66cCJHYbVGuqTTNhcA1MD1W_RlAs/edit#gid=0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {'Google Sheet'}
                    </a>
                    {' or download this '}
                    <a
                      href="/docs/maker-wine-group-ordering-address-form.xlsx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {'Excel sheet'}
                    </a>
                    {'.'}
                  </Text>
                </Box>
                <Box as="li">
                  <Text as="p">
                    {
                      'Provide all of the required information for each recipient.'
                    }
                  </Text>
                </Box>
                <Box as="li">
                  <Text as="p">{'Save a CSV file:'}</Text>
                  <Box as="ul">
                    <Box as="li">{'Google Sheet: File > Download > CSV'}</Box>
                    <Box as="li">
                      {'Excel sheet: Save As > Save as type: CSV'}
                    </Box>
                  </Box>
                </Box>
                <Box as="li">{'Upload the CSV below.'}</Box>
              </Box>
              <GroupOrderAddressesStepUploadForm />
              <Text as="p" variant="body3" mt={2} mb={3}>
                {
                  'You will have the option to add a gift note at the next step.'
                }
              </Text>
              <Button onClick={() => handleContinue()} sx={{ width: '100%' }}>
                Continue
              </Button>
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default GroupOrderAddressesStep
