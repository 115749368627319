import React, { useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import { Box } from 'theme-ui'
import theme from '~/gatsby-plugin-theme-ui'
import ContentPage from '~/components/Content/Page'
import GroupOrderPager from '~/components/GroupOrder/Pager'
import GroupOrderAddonsStep from '~/components/GroupOrder/Step/Addons'
import GroupOrderAddressesStep from '~/components/GroupOrder/Step/Addresses'
import GroupOrderEstimateStep from '~/components/GroupOrder/Step/Estimate'
import GroupOrderFinalizeStep from '~/components/GroupOrder/Step/Finalize'
import GroupOrderSelectStep from '~/components/GroupOrder/Step/Select'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { useGroupOrder } from '~/hooks/components/use-group-order'

const STEPS = [
  {
    slug: 'select',
    label: 'Select a box',
    number: 1,
  },
  {
    slug: 'addons',
    label: 'Add-ons',
    number: 2,
  },
  {
    slug: 'estimate',
    label: 'Estimate',
    number: 3,
  },
  {
    slug: 'addresses',
    label: 'Addresses',
    number: 4,
  },
  {
    slug: 'finalize',
    label: 'Finalize',
    number: 5,
  },
]

const ADDON_SKUS = {
  virtualTasting: 'group-virtual-tasting',
}

const GroupOrder = ({ data }) => {
  const { collection, footer } = data
  const {
    requestEstimate,
    submitted,
    updateAddons,
    updateDetails,
    updateProducts,
  } = useGroupOrder()
  const [currentStep, setCurrentStep] = useState(STEPS[0])
  const prevStep = STEPS.find((step) => step.number === currentStep.number - 1)
  const nextStep = STEPS.find((step) => step.number === currentStep.number + 1)

  const handleTunneledBack =
    currentStep.slug === 'select'
      ? () => navigate('/corporate-wine-gifts/')
      : !submitted
      ? () => setCurrentStep(prevStep)
      : null

  STEPS.forEach((step, i) => {
    if (step === currentStep) {
      step.status = 'current'
    } else if (step.number < currentStep.number) {
      step.status = 'complete'
    } else {
      step.status = 'pending'
    }
  })

  // Powers linking from estimate step inputs.
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const estimateUrl = `${window.location.origin}${
      window.location.pathname
    }?${params.toString()}`

    const date = params.get('godate')
    const email = params.get('goaddr')
    const hasVirtualTasting = params.get('gotast') === '1'
    const productSku = params.get('goprod')
    const size = params.get('gosize')
    const time = params.get('gotime')

    if (
      date &&
      email &&
      size &&
      productSku &&
      !!collection.listings.find((listing) =>
        listing.kits.find((kit) => kit.sku === productSku)
      )
    ) {
      updateDetails({
        date,
        email,
        size,
        time,
      })

      updateProducts([{ quantity: 1, sku: productSku }])

      if (hasVirtualTasting) {
        updateAddons([{ quantity: 1, sku: ADDON_SKUS.virtualTasting }])
      }

      setCurrentStep(STEPS[2])
      requestEstimate(estimateUrl)
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  return (
    <Layout tunneled={true} handleTunneledBack={handleTunneledBack}>
      <Metadata noindex={true} />
      <Box bg="backgroundLight">
        <GroupOrderPager
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          steps={STEPS}
          submitted={submitted}
        />
        {currentStep === STEPS[0] && (
          <GroupOrderSelectStep
            collection={collection}
            stepForward={() => setCurrentStep(nextStep)}
          />
        )}
        {currentStep === STEPS[1] && (
          <GroupOrderAddonsStep
            addonSkus={ADDON_SKUS}
            stepBackward={() => setCurrentStep(prevStep)}
            stepForward={() => setCurrentStep(nextStep)}
          />
        )}
        {currentStep === STEPS[2] && (
          <GroupOrderEstimateStep
            addonSkus={ADDON_SKUS}
            collection={collection}
            requestEstimate={requestEstimate}
            stepForward={() => setCurrentStep(nextStep)}
          />
        )}
        {currentStep === STEPS[3] && (
          <GroupOrderAddressesStep
            stepForward={() => setCurrentStep(nextStep)}
          />
        )}
        {currentStep === STEPS[4] && <GroupOrderFinalizeStep />}
      </Box>
      <ContentPage page={footer} />
      <style>{`html { background-color: ${theme.colors.backgroundMedium}; }`}</style>
    </Layout>
  )
}

export default GroupOrder

export const query = graphql`
  query GroupOrderPage {
    collection: contentfulCollection(slug: { eq: "group-orders" }) {
      ...CollectionDetailsFragment
    }
    footer: contentfulPage(slug: { eq: "group-order" }) {
      sections {
        ...ContentSectionFragment
      }
    }
  }
`
