/** @jsx jsx */
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { Box, Button, Container, Grid, Heading, jsx, Text } from 'theme-ui'
import { identify } from '@chordcommerce/chord-js-autonomy/dist/analytics/utils'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import FormInput from '~/components/Generic/Form/Input'
import { useAddToCart } from '~/hooks/components/use-add-to-cart'
import { useGroupOrder } from '~/hooks/components/use-group-order'
import { toUsdCurrency } from '~/utils/formatters'

const GroupOrderEstimateStepForm = ({
  addonSkus,
  shippingPrice,
  virtualTastingPrice,
}) => {
  const { getAddToCartDataBySku } = useAddToCart()
  const { register, handleSubmit, errors, watch } = useForm()
  const { addons, details, products, requestEstimate, updateDetails } =
    useGroupOrder()
  const translate = useTranslate()

  const hasVirtualTasting = addons?.[0]?.sku === addonSkus.virtualTasting
  const dateLabel = hasVirtualTasting
    ? 'Target tasting date'
    : 'Choose target shipping date'
  const dateValue = watch('date')
  const timeLabel = 'Target tasting time'

  const isValidDate = useCallback((value) => {
    return moment(value, 'YYYY-MM-DD').diff(moment(), 'days') > 0
  }, [])

  const isCloseDate = useCallback(
    (value) => {
      return (
        isValidDate(value) &&
        moment(value, 'YYYY-MM-DD').diff(moment(), 'days') < 13
      )
    },
    [isValidDate]
  )

  const onSubmit = async (data) => {
    const params = new URLSearchParams({
      goaddr: data.emailAddress,
      godate: data.date,
      goprod: products?.[0]?.sku,
      gosize: data.groupSize,
      gotast: hasVirtualTasting ? '1' : '0',
      gotime: data.time,
    })
    const estimateUrl = `${window.location.origin}${
      window.location.pathname
    }?${params.toString()}`

    await updateDetails({
      date: data.date,
      email: data.emailAddress,
      size: data.groupSize,
      time: data.time,
    })

    const productData = getAddToCartDataBySku(products?.[0]?.sku)
    const productPriceUnit = productData?.price
    const productPriceTotal = productData?.price * data.groupSize
    const shippingPriceTotal = shippingPrice * data.groupSize
    const virtualTastingPriceTotal = hasVirtualTasting ? virtualTastingPrice : 0
    const estimatedPriceTotal =
      productPriceTotal + shippingPriceTotal + virtualTastingPriceTotal

    await requestEstimate(estimateUrl, {
      productName: productData?.name,
      productPriceUnit: toUsdCurrency(productPriceUnit, true),
      productPriceTotal: toUsdCurrency(productPriceTotal, true),
      shippingPriceUnit: toUsdCurrency(shippingPrice, true),
      shippingPriceTotal: toUsdCurrency(shippingPriceTotal, true),
      virtualTasting: hasVirtualTasting ? 'Yes' : 'No',
      virtualTastingPrice: toUsdCurrency(virtualTastingPriceTotal, true),
      totalEstimatedPrice: toUsdCurrency(estimatedPriceTotal, true),
    })

    await identify('', { email: data.emailAddress })
  }

  return (
    <Box pt={[6, null, 8]} pb={[9, null, 10]}>
      <Container>
        <Grid columns={[null, 12]}>
          <Box
            sx={{
              gridColumn: [null, '2 / 12', '3 / 11', '4 / 10'],
            }}
          >
            <Box mb={5} sx={{ textAlign: 'center' }}>
              <Heading as="h1" variant="title2" mb={[1, null, 2]}>
                {'Get an Estimate'}
              </Heading>
              <Text as="p" variant="body2">
                {
                  "Enter your email and group size for an estimate! We'll email it for you to have handy along with a link to save your progress."
                }
              </Text>
            </Box>
            <form name="get-estimate" onSubmit={handleSubmit(onSubmit)}>
              <Box mb={3}>
                <FormInput
                  name="emailAddress"
                  title={'Email address'}
                  label={'Email address'}
                  aria-label={'Email address'}
                  type="email"
                  error={errors?.emailAddress?.message}
                  required={true}
                  defaultValue={details?.email}
                  ref={register({
                    required: {
                      value: true,
                      message: translate('validation.required_field'),
                    },
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: translate('validation.invalid_email'),
                    },
                  })}
                />
              </Box>
              <Grid columns={[null, 8]} mb={3}>
                <Box sx={{ gridColumn: [null, '1 / 3'] }}>
                  <FormInput
                    name="groupSize"
                    title={'Group size'}
                    label={'Group size'}
                    aria-label={'Group size'}
                    type="number"
                    min="0"
                    step="1"
                    error={errors?.groupSize?.message}
                    required={true}
                    defaultValue={details?.size}
                    ref={register({
                      required: {
                        value: true,
                        message: translate('validation.required_field'),
                      },
                    })}
                  />
                </Box>
                <Box
                  sx={{
                    gridColumn: [null, hasVirtualTasting ? '3 / 6' : '3 / -1'],
                  }}
                >
                  <FormInput
                    name="date"
                    title={dateLabel}
                    label={dateLabel}
                    aria-label={dateLabel}
                    type="date"
                    min="0"
                    step="1"
                    error={errors?.date?.message}
                    required={true}
                    defaultValue={details?.date}
                    ref={register({
                      required: {
                        value: true,
                        message: translate('validation.required_field'),
                      },
                      validate: (value) =>
                        isValidDate(value) ||
                        'Must be at least two days in advance.',
                    })}
                  />
                </Box>
                {hasVirtualTasting && (
                  <Box sx={{ gridColumn: [null, '6 / -1'] }}>
                    <FormInput
                      name="time"
                      title={timeLabel}
                      label={timeLabel}
                      aria-label={timeLabel}
                      type="time"
                      error={errors?.time?.message}
                      defaultValue={details?.time}
                      ref={register()}
                    />
                  </Box>
                )}
              </Grid>
              {isCloseDate(dateValue) && (
                <Text as="p" variant="body3" mt={-1} mb={2} color="greyDark">
                  {`We cannot guarantee shipping timelines for orders placed with less than 14 days before your desired delivery date. Please contact us to confirm shipping timelines and/or discuss expedited shipping.`}
                </Text>
              )}
              <Button type="submit" sx={{ width: '100%' }}>
                {'Get estimate'}
              </Button>
            </form>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default GroupOrderEstimateStepForm
