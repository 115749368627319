export const getGroupOrderAddons = (state) => state?.groupOrder?.addons

export const getGroupOrderAddresses = (state) => state?.groupOrder?.addresses

export const getGroupOrderDetails = (state) => state?.groupOrder?.details

export const getGroupOrderError = (state) => state?.groupOrder?.error

export const getGroupOrderEstimateUrl = (state) =>
  state?.groupOrder?.estimateUrl

export const getGroupOrderProducts = (state) => state?.groupOrder?.products

export const getGroupOrderSubmitted = (state) => state?.groupOrder?.submitted
