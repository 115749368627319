/** @jsx jsx */
import { Fragment, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Button, Flex, Heading, jsx, Text } from 'theme-ui'
import Modal from '~/components/Generic/Modal'
import ListingCardSpecs from '~/components/Listing/Card/Specs'
import PackCallout from '~/components/Pack/Callout'
import { toUsdCurrency } from '~/utils/formatters'
import { getAvailablePackKits } from '~/utils/packs'

const GroupOrderSelectPageCard = ({ handleOptionClick, pack }) => {
  const availableKits = getAvailablePackKits(pack)
  const kit = availableKits[0]
  const { sku } = kit

  return (
    <Flex
      mx="auto"
      sx={{
        flexDirection: 'column',
        height: '100%',
        maxWidth: '400px',
        justifyContent: 'flex-start',
        width: '100%',
      }}
    >
      <Box onClick={() => handleOptionClick(sku)}>
        <GroupOrderSelectPageCardImage pack={pack} />
      </Box>
      <GroupOrderSelectPageCardBody
        handleOptionClick={handleOptionClick}
        kit={kit}
        pack={pack}
      />
    </Flex>
  )
}

const GroupOrderSelectPageCardImage = ({ pack }) => {
  const { customLabel, hoverImage, mainImage } = pack

  return (
    <Box
      sx={{
        cursor: 'pointer',
        display: 'block',
        position: 'relative',
        'div:nth-of-type(2)': { opacity: '0', visibility: 'hidden' },
        '&:hover div:nth-of-type(2)': {
          opacity: [null, '1'],
          visibility: [null, 'visible'],
        },
      }}
    >
      <Box>
        <GatsbyImage
          image={mainImage.gatsbyImageData}
          alt={mainImage.description}
          key={mainImage.title}
          sx={{ display: 'block' }}
        />
      </Box>
      {hoverImage && (
        <Box
          sx={{
            left: '0',
            pointerEvents: 'none',
            position: 'absolute',
            right: '0',
            top: '0',
          }}
        >
          <GatsbyImage
            image={hoverImage.gatsbyImageData}
            alt={hoverImage.description}
            key={hoverImage.title}
          />
        </Box>
      )}
      {customLabel && (
        <Box sx={{ left: 0, position: 'absolute', top: 0 }}>
          <PackCallout pack={pack} />
        </Box>
      )}
    </Box>
  )
}

const GroupOrderSelectPageCardBody = ({ handleOptionClick, kit, pack }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { mainImage, name, shortDescription, subtitle, tags } = pack
  const { price, sku } = kit

  return (
    <Fragment>
      <Flex
        mt={2}
        px={1}
        sx={{
          alignItems: 'left',
          flexDirection: 'column',
          flexGrow: '1',
          justifyContent: 'flex-start',
        }}
      >
        <Box mb={2} sx={{ flexGrow: '1' }}>
          <Heading
            as="h2"
            variant="title5"
            mb="4px"
            sx={{
              cursor: 'pointer',
              '&:hover': { textDecoration: 'underline' },
            }}
            onClick={() => setIsExpanded(true)}
          >
            {name}
          </Heading>
          {subtitle && (
            <Text
              as="h3"
              variant="body3"
              mb={1}
              sx={{
                color: 'greyDark',
                fontWeight: 'normal',
              }}
            >
              {subtitle}
            </Text>
          )}
          <Text as="p" variant="body3">
            {shortDescription}
          </Text>
          <Button
            variant="reset"
            onClick={() => setIsExpanded(true)}
            mt={1}
            mb={1}
          >
            <Text
              as="span"
              variant="body2"
              sx={{
                color: 'brandPrimary',
                textDecoration: 'underline',
              }}
            >{`View details`}</Text>
          </Button>
          <Text as="p" variant="title6">{`${toUsdCurrency(
            price
          )} per person`}</Text>
        </Box>
        <Button onClick={() => handleOptionClick(sku)} sx={{ width: '100%' }}>
          {'Select & Continue'}
        </Button>
      </Flex>
      {isExpanded && (
        <Modal onClose={() => setIsExpanded(false)} maxWidth="474px">
          <ListingCardSpecs
            footer={<GroupOrderSelectPageCardIncluded kit={kit} />}
            image={mainImage}
            subtitle={subtitle}
            tags={tags}
            title={name}
          />
        </Modal>
      )}
    </Fragment>
  )
}

const GroupOrderSelectPageCardIncluded = ({ kit }) => {
  const { products } = kit

  const kitContents = []
  products.forEach((product) => {
    const i = kitContents.findIndex(({ slug }) => slug === product.slug)

    if (i === -1) {
      kitContents.push({
        description: product.variants?.[0]?.clubDescription,
        name: product.name,
        quantity: 1,
        slug: product.slug,
      })
    } else {
      kitContents[i].quantity = kitContents[i].quantity + 1
    }
  })

  if (!kitContents.length) return <Fragment />

  return (
    <Box bg="backgroundLight" p={[2, 3]}>
      <Heading as="h3" variant="title6" mb={2}>
        {'This gift includes:'}
      </Heading>
      {kitContents.map((item) => (
        <Box
          key={`kit-contents-${item.slug}`}
          sx={{ '&:not(:last-of-type)': { mb: 2 } }}
        >
          <Text as="p" variant="body3" sx={{ fontWeight: 'bold' }}>
            {item.name}
            {item.quantity > 1 && ` (${item.quantity})`}
          </Text>
          <Box
            dangerouslySetInnerHTML={{
              __html: item.description?.childMarkdownRemark.html,
            }}
            sx={{ variant: 'text.description', p: { color: 'greyDark', m: 0 } }}
          />
        </Box>
      ))}
    </Box>
  )
}

export default GroupOrderSelectPageCard
