/** @jsx jsx */
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  jsx,
  Text,
} from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useGroupOrder } from '~/hooks/components/use-group-order'
import { toUsdCurrency } from '~/utils/formatters'

const GroupOrderEstimateStepEstimate = ({
  addonSkus,
  collection,
  shippingPrice,
  stepForward,
  virtualTastingPrice,
}) => {
  const { addons, details, products } = useGroupOrder()

  const selectedPack = collection.listings.find((listing) =>
    listing.kits.find((kit) => kit.sku === products?.[0].sku)
  )
  const { kits, mainImage, name } = selectedPack
  const { price } = kits[0]
  const { size } = details

  const hasVirtualTasting = addons.find(
    (addon) => addon.sku === addonSkus.virtualTasting
  )

  let subtotal = (price + shippingPrice) * size
  if (hasVirtualTasting) subtotal = subtotal + virtualTastingPrice

  return (
    <Box pt={[6, null, 8]} pb={[9, null, 10]}>
      <Container>
        <Grid columns={[null, 12]}>
          <Box
            sx={{
              gridColumn: [null, '3 / 11', '3 / 11', '4 / 10'],
            }}
          >
            <Box mb={5} sx={{ textAlign: 'center' }}>
              <Heading as="h1" variant="title2" mb={[1, null, 2]}>
                {'Get an Estimate'}
              </Heading>
              <Text as="p" variant="body2">
                {
                  "This estimate has been sent to your email. Continue below or return when you're ready to finalize."
                }
              </Text>
            </Box>
            <Box bg="white" mb={3} p={2}>
              <Flex
                sx={{
                  borderBottom: '1px solid',
                  borderBottomColor: 'greyLight',
                  flexDirection: ['column', 'row'],
                }}
              >
                {mainImage && (
                  <Box
                    mb={[2, 0]}
                    mr={2}
                    sx={{ height: '152px', width: '152px' }}
                  >
                    <GatsbyImage
                      image={mainImage.gatsbyImageData}
                      alt={mainImage.description}
                      sx={{ display: 'block' }}
                    />
                  </Box>
                )}
                <Box sx={{ flexGrow: '1' }}>
                  <Box mb={1}>
                    <Heading as="h2" variant="title6" mb={1}>
                      {name}
                    </Heading>
                    <GroupOrderEstimateStepEstimateMeta
                      price={price}
                      quantity={size}
                    />
                  </Box>
                  <Box mb={2}>
                    <Heading as="h3" variant="title6" mb={1}>
                      {'Estimated Shipping'}
                    </Heading>
                    <GroupOrderEstimateStepEstimateMeta
                      price={shippingPrice}
                      quantity={size}
                    />
                  </Box>
                </Box>
              </Flex>
              {hasVirtualTasting && (
                <Flex
                  py={2}
                  sx={{
                    borderBottom: '1px solid',
                    borderBottomColor: 'greyLight',
                    justifyContent: 'space-between',
                  }}
                >
                  <Heading as="h2" variant="title6" mb={1}>
                    {'Virtual Tasting'}
                  </Heading>
                  <Text as="p" variant="body2">
                    {toUsdCurrency(virtualTastingPrice)}
                  </Text>
                </Flex>
              )}
              <Flex
                pt={2}
                sx={{ alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Box>
                  <Text as="p" variant="description" color="greyDark">
                    {'Taxes will be calculated at checkout.'}
                  </Text>
                </Box>
                <Box>
                  <GroupOrderEstimateStepEstimateMetaItem
                    align="right"
                    label="Subtotal"
                    value={toUsdCurrency(subtotal)}
                    valueWeight="bold"
                  />
                </Box>
              </Flex>
            </Box>
            <Button onClick={() => stepForward()} sx={{ width: '100%' }}>
              {'Continue'}
            </Button>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

const GroupOrderEstimateStepEstimateMeta = ({ price, quantity }) => {
  return (
    <Flex sx={{ width: '100%' }}>
      <GroupOrderEstimateStepEstimateMetaItem
        label="Unit price"
        value={toUsdCurrency(price)}
        width="33.3333%"
      />
      <GroupOrderEstimateStepEstimateMetaItem
        label="Quantity"
        value={quantity}
        width="33.3333%"
      />
      <GroupOrderEstimateStepEstimateMetaItem
        align="right"
        label="Total"
        value={toUsdCurrency(price * quantity, true)}
        width="33.3333%"
      />
    </Flex>
  )
}

const GroupOrderEstimateStepEstimateMetaItem = ({
  align,
  label,
  value,
  valueWeight,
  width,
}) => {
  return (
    <Box sx={{ textAlign: align || 'left', width }}>
      <Heading
        as="h3"
        variant="finePrint"
        mb="4px"
        sx={{ fontWeight: 'normal' }}
      >
        {label}
      </Heading>
      <Text as="p" variant="body2" sx={{ fontWeight: valueWeight || 'normal' }}>
        {value}
      </Text>
    </Box>
  )
}

export default GroupOrderEstimateStepEstimate
