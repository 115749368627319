/** @jsx jsx */
import { Box, Container, Grid, Heading, jsx, Text } from 'theme-ui'
import GroupOrderSelectStepCard from '~/components/GroupOrder/Step/Select/Card'
import { useGroupOrder } from '~/hooks/components/use-group-order'

const GroupOrderSelectStep = ({ collection, stepForward }) => {
  const { updateProducts } = useGroupOrder()
  const { description, listings, title } = collection

  const handleOptionClick = async (sku) => {
    await updateProducts([{ quantity: 1, sku }])
    stepForward()
  }

  return (
    <Box pt={[5, null, 7]} pb={[8, null, 10]}>
      <Container>
        <Box mb={[5, null, 8]}>
          <Grid columns={[1, 12]} sx={{ rowGap: '0' }}>
            <Box
              sx={{
                gridColumn: [null, '2 / 12', '3 / 11', '4 / 10'],
                textAlign: 'center',
              }}
            >
              <Heading as="h1" variant="title2" mb={[1, null, null, 2]}>
                {title}
              </Heading>
              {description && (
                <Text
                  dangerouslySetInnerHTML={{
                    __html: description.childMarkdownRemark.html,
                  }}
                  variant="body2"
                  sx={{ p: { m: 0 } }}
                />
              )}
            </Box>
          </Grid>
        </Box>
        <Grid columns={[1, 2, 3]} sx={{ rowGap: 9 }}>
          {listings.map((pack) => {
            const { slug } = pack

            return (
              <Box key={`pack-option-${slug}`}>
                <GroupOrderSelectStepCard
                  handleOptionClick={handleOptionClick}
                  pack={pack}
                />
              </Box>
            )
          })}
        </Grid>
      </Container>
    </Box>
  )
}

export default GroupOrderSelectStep
