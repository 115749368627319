import {
  GROUP_ORDER_REQUEST_ESTIMATE,
  GROUP_ORDER_SET_ERROR,
  GROUP_ORDER_SUBMIT,
  GROUP_ORDER_UPDATE_ADDONS,
  GROUP_ORDER_UPDATE_ADDRESSES,
  GROUP_ORDER_UPDATE_DETAILS,
  GROUP_ORDER_UPDATE_PRODUCTS,
} from '~/redux/actions/types'

export const requestEstimate = (url, totals = {}) => {
  return {
    type: GROUP_ORDER_REQUEST_ESTIMATE,
    payload: {
      totals,
      url,
    },
  }
}

export const setError = (error, data) => {
  return {
    type: GROUP_ORDER_SET_ERROR,
    payload: {
      data,
      error,
    },
  }
}

export const submit = (data) => {
  return {
    type: GROUP_ORDER_SUBMIT,
    payload: {
      data,
    },
  }
}

export const updateAddons = (addons) => {
  return {
    type: GROUP_ORDER_UPDATE_ADDONS,
    payload: {
      addons,
    },
  }
}

export const updateAddresses = (addresses) => {
  return {
    type: GROUP_ORDER_UPDATE_ADDRESSES,
    payload: {
      addresses,
    },
  }
}

export const updateDetails = (details) => {
  return {
    type: GROUP_ORDER_UPDATE_DETAILS,
    payload: {
      details,
    },
  }
}

export const updateProducts = (products) => {
  return {
    type: GROUP_ORDER_UPDATE_PRODUCTS,
    payload: {
      products,
    },
  }
}
