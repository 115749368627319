import { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../redux/actions/group-order'
import * as selectors from '../../redux/selectors/group-order'

export function useGroupOrder() {
  const dispatch = useDispatch()

  const addons = useSelector(selectors.getGroupOrderAddons)
  const addresses = useSelector(selectors.getGroupOrderAddresses)
  const details = useSelector(selectors.getGroupOrderDetails)
  const error = useSelector(selectors.getGroupOrderError)
  const estimateUrl = useSelector(selectors.getGroupOrderEstimateUrl)
  const products = useSelector(selectors.getGroupOrderProducts)
  const submitted = useSelector(selectors.getGroupOrderSubmitted)

  const requestEstimate = useMemo(
    () => bindActionCreators(actions.requestEstimate, dispatch),
    [dispatch]
  )

  const setError = useMemo(
    () => bindActionCreators(actions.setError, dispatch),
    [dispatch]
  )

  const submit = useMemo(
    () => bindActionCreators(actions.submit, dispatch),
    [dispatch]
  )

  const updateAddons = useMemo(
    () => bindActionCreators(actions.updateAddons, dispatch),
    [dispatch]
  )

  const updateAddresses = useMemo(
    () => bindActionCreators(actions.updateAddresses, dispatch),
    [dispatch]
  )

  const updateDetails = useMemo(
    () => bindActionCreators(actions.updateDetails, dispatch),
    [dispatch]
  )

  const updateProducts = useMemo(
    () => bindActionCreators(actions.updateProducts, dispatch),
    [dispatch]
  )

  return {
    addons,
    addresses,
    details,
    error,
    estimateUrl,
    products,
    requestEstimate,
    setError,
    submit,
    submitted,
    updateAddons,
    updateAddresses,
    updateDetails,
    updateProducts,
  }
}
