/** @jsx jsx */
import { jsx } from 'theme-ui'
import GroupOrderEstimateStepEstimate from '~/components/GroupOrder/Step/Estimate/Estimate'
import GroupOrderEstimateStepForm from '~/components/GroupOrder/Step/Estimate/Form'
import { useGroupOrder } from '~/hooks/components/use-group-order'

const GroupOrderEstimateStep = ({
  addonSkus,
  collection,
  requestEstimate,
  stepForward,
}) => {
  const { estimateUrl } = useGroupOrder()
  const shippingPrice = 7.99
  const virtualTastingPrice = 150

  if (estimateUrl) {
    return (
      <GroupOrderEstimateStepEstimate
        addonSkus={addonSkus}
        collection={collection}
        shippingPrice={shippingPrice}
        stepForward={stepForward}
        virtualTastingPrice={virtualTastingPrice}
      />
    )
  }

  return (
    <GroupOrderEstimateStepForm
      addonSkus={addonSkus}
      requestEstimate={requestEstimate}
      shippingPrice={shippingPrice}
      virtualTastingPrice={virtualTastingPrice}
    />
  )
}

export default GroupOrderEstimateStep
