/** @jsx jsx */
import { Box, Button, Container, Flex, Grid, jsx, Text } from 'theme-ui'
import CheckmarkIcon from '~/assets/images/icons/checkmark.inline.svg'

const GroupOrderPagerStep = ({
  currentStep,
  setCurrentStep,
  step,
  submitted,
}) => {
  const { label, number, slug, status } = step
  const stepColors = {
    complete: 'brandSecondary',
    current: 'brandPrimary',
    pending: 'greyLight',
    pendingText: 'greyDark',
  }
  const isVisibleWhenTruncated =
    currentStep === step ||
    currentStep.number === step.number - 1 ||
    currentStep.number === step.number + 1 ||
    step.number === 3

  return (
    <Box
      mb="-6px"
      sx={{
        display: [isVisibleWhenTruncated ? 'block' : 'none', 'block'],
        cursor: 'default',
        position: 'relative',
        textAlign: 'center',
        width: '100px',
        '&:before': {
          backgroundColor: submitted
            ? stepColors.current
            : status === 'complete'
            ? stepColors.complete
            : stepColors.pending,
          content: [slug !== 'finalize' ? '""' : 'none', 'none'],
          display: ['block', 'none'],
          height: '4px',
          position: 'absolute',
          left: '70px',
          top: '18px',
          width: '120px',
        },
        '&:after': {
          backgroundColor: submitted
            ? stepColors.current
            : status === 'current'
            ? stepColors.complete
            : stepColors[status],
          content: number > 1 ? '""' : 'none',
          display: 'block',
          height: '4px',
          position: 'absolute',
          right: '70px',
          top: '18px',
          width: '120px',
        },
      }}
    >
      {(submitted || status === 'complete') && (
        <Button
          variant="reset"
          bg={submitted ? stepColors.current : stepColors[status]}
          mx="auto"
          aria-label={`Navigate to ${label} step.`}
          onClick={() => !submitted && setCurrentStep(step)}
          sx={{
            borderRadius: '50%',
            cursor: submitted ? 'auto' : 'pointer',
            display: 'block',
            height: '40px',
            position: 'relative',
            width: '40px',
            zIndex: '1',
          }}
        >
          <CheckmarkIcon
            sx={{
              color: 'white',
              position: 'relative',
            }}
          />
        </Button>
      )}
      {!submitted && status !== 'complete' && (
        <Box
          bg={stepColors[status]}
          mx="auto"
          sx={{
            borderRadius: '50%',
            height: '40px',
            position: 'relative',
            width: '40px',
            zIndex: '1',
          }}
        >
          <Text
            as="strong"
            variant="title5"
            color={status === 'pending' ? stepColors.pendingText : 'white'}
            sx={{ position: 'relative', top: ['5px', '3px'] }}
          >
            {number}
          </Text>
        </Box>
      )}
      <Text
        variant="finePrint"
        color={
          submitted
            ? stepColors.current
            : status === 'pending'
            ? stepColors.pendingText
            : stepColors[status]
        }
      >
        {label}
      </Text>
    </Box>
  )
}

const GroupOrderPager = ({ currentStep, setCurrentStep, steps, submitted }) => {
  return (
    <Box bg="white" py={3}>
      <Container>
        <Grid columns={(null, 12)}>
          <Flex
            mx={[-2, 0]}
            sx={{
              gridColumn: ['1 / -1', null, '2 / 12', '3 / 11'],
              justifyContent: 'space-between',
              maxWidth: '740px',
              overflow: 'hidden',
            }}
          >
            {steps.map((step) => (
              <GroupOrderPagerStep
                key={`pager-step-${step.slug}`}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                step={step}
                submitted={submitted}
              />
            ))}
          </Flex>
        </Grid>
      </Container>
    </Box>
  )
}

export default GroupOrderPager
