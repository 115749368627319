/** @jsx jsx */
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import {
  Box,
  Button,
  Container,
  Grid,
  Heading,
  jsx,
  Spinner,
  Text,
} from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import FormInput from '~/components/Generic/Form/Input'
import FormTextarea from '~/components/Generic/Form/Textarea'
import { useAddToCart } from '~/hooks/components/use-add-to-cart'
import { useGroupOrder } from '~/hooks/components/use-group-order'

const GroupOrderFinalizeStepForm = () => {
  const { getAddToCartDataBySku } = useAddToCart()
  const { register, handleSubmit, errors } = useForm()
  const {
    addons,
    addresses,
    details,
    error,
    products,
    setError,
    submit,
    updateDetails,
  } = useGroupOrder()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const translate = useTranslate()

  // Batch orders don't yet support bundles, so find the parts.
  const selectedProductData = getAddToCartDataBySku(products[0].sku)
  const orderProductColumns = {}
  selectedProductData?.parts?.forEach((part, i) => {
    orderProductColumns[`sku${i + 1}`] = part.sku
    orderProductColumns[`qty${i + 1}`] = part.quantity * products?.[0]?.quantity
  })

  const compileBatchOrder = async (details) => {
    const tagParts = ['Group']
    if (details?.company?.length) {
      tagParts.push(details?.company?.substring(0, 10))
    }

    const orders = addresses.map((address) => ({
      email: address.email,
      'first name': address.firstName,
      'last name': address.lastName,
      company: details?.company?.substring(0, 50),
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      'state name': address.state,
      zipcode: address.zipcode,
      gift_note_note: details.giftMessage,
      tags: tagParts.join(' - '),
      // single_payer: 'YES',
      // single_payer_email: details.email,
      // ship_now: 'NO',
      ...orderProductColumns,
    }))

    if (addons.length > 0) {
      orders.push({
        email: details.email,
        'first name': details.firstName,
        'last name': details.lastName,
        company: details?.company?.substring(0, 50),
        address1: addresses?.[0]?.address1,
        address2: addresses?.[0]?.address2,
        city: addresses?.[0]?.city,
        'state name': addresses?.[0]?.state,
        zipcode: addresses?.[0]?.zipcode,
        gift_note_note: details.giftMessage,
        tags: tagParts.join(' - '),
        // single_payer: 'YES',
        // single_payer_email: details.email,
        // ship_now: 'NO',
        sku1: addons?.[0]?.sku,
        qty1: addons?.[0]?.quantity,
      })
    }

    return orders
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true)

    await updateDetails({
      company: data.company,
      firstName: data.firstName,
      giftMessage: data.giftMessage,
      lastName: data.lastName,
    })

    const batchOrderDetails = { ...details, ...data }

    try {
      await compileBatchOrder(batchOrderDetails)
        .then((batchOrder) =>
          // @see ~/functions/group-order.mjs
          axios.post('/.netlify/functions/group-order', {
            data: { order: batchOrder, details: batchOrderDetails },
          })
        )
        .then((response) => {
          if (response.status === 200) {
            setIsSubmitting(false)
            submit()
          }
        })
    } catch (error) {
      setIsSubmitting(false)
      setError('An error occurred while submitting your information.')
    }
  }

  return (
    <Box pt={[6, null, 8]} pb={[9, null, 10]}>
      <Container>
        <Grid columns={[null, 12]}>
          <Box
            sx={{
              gridColumn: [null, '3 / 11', '3 / 11', '4 / 10'],
            }}
          >
            <Heading as="h1" variant="title2" mb={[2, null, 3]}>
              {'Finalize the Invoice'}
            </Heading>
            <form name="get-estimate" onSubmit={handleSubmit(onSubmit)}>
              <Text as="p" variant="body2" mb={2}>
                {'1. Please provide your contact information.'}
              </Text>
              <Grid columns={[1, 2]} mb={2}>
                <Box>
                  <FormInput
                    name="firstName"
                    title={'First name'}
                    label={'First name'}
                    aria-label={'First name'}
                    type="text"
                    error={errors?.firstName?.message}
                    required={true}
                    defaultValue={details?.firstName}
                    ref={register({
                      required: {
                        value: true,
                        message: translate('validation.required_field'),
                      },
                    })}
                  />
                </Box>
                <Box>
                  <FormInput
                    name="lastName"
                    title={'Last name'}
                    label={'Last name'}
                    aria-label={'Last name'}
                    type="text"
                    error={errors?.lastName?.message}
                    required={true}
                    defaultValue={details?.lastName}
                    ref={register({
                      required: {
                        value: true,
                        message: translate('validation.required_field'),
                      },
                    })}
                  />
                </Box>
              </Grid>
              <Box mb={3}>
                <FormInput
                  name="company"
                  title={'Company name'}
                  label={'Company name'}
                  aria-label={'Company name'}
                  type="text"
                  error={errors?.company?.message}
                  defaultValue={details?.company}
                  ref={register()}
                />
              </Box>
              <Text as="p" variant="body2" mb={2}>
                {
                  "2. Enter your gift note below. We'll put this on a card in each gift box. (optional)"
                }
              </Text>
              <Box mb={3}>
                <FormTextarea
                  name="giftMessage"
                  title={'Gift message'}
                  label={'Gift message'}
                  aria-label={'Gift message'}
                  ref={register()}
                />
              </Box>
              <Button type="submit" sx={{ width: '100%' }}>
                {!isSubmitting && 'Submit'}
                {isSubmitting && (
                  <Spinner
                    size="24"
                    color="inherit"
                    mb={-1}
                    sx={{
                      position: 'relative',
                      top: '-2px',
                      '@media (prefers-reduced-motion)': {
                        circle: {
                          animationIterationCount: 8,
                        },
                      },
                    }}
                  />
                )}
              </Button>
              {error && (
                <Text as="p" variant="body3" color="error" mt={2}>
                  {`${error} Please try again and if you continue to have trouble, reach out to our events concierge at hq@makerwine.com.`}
                </Text>
              )}
            </form>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default GroupOrderFinalizeStepForm
